import styled from "styled-components"
import { device } from "breakpoints"

export const CircleImageWrapper = styled.div`
  border-radius: 50%;
  width: 215px;
  height: 215px;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.15);
  margin-bottom: 59px;
  flex-shrink: 0;
  position: relative;
  @media ${device.tablet} {
    height: auto;
    width: calc(50% - 15px);
    padding-top: calc(50% - 15px);
    margin-right: 30px;
    margin-bottom: 105px;
  }

  @media ${device.desktop} {
    width: calc(50% - 20px);
    padding-top: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 160px;
    margin-top: 15px;
  }

  .avenueImg,
  .icon {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .icon {
    z-index: 2;
  }
`

export const Excerpt = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    text-align: center;
    margin-bottom: 17px;
    width: 100%;

    @media ${device.tablet} {
      text-align: left;
      margin-bottom: 20px;
    }
  }
`
