import React from "react"
import { Heading, Paragraph } from "./style"
import { Grid, Row, Col } from "components/UI/Grid"
import { Spacer } from "components/UI/Spacer"
import { Highlight } from "./Highlight"
import { Video } from "./Video"
import { Box } from "UI"

const HighlightsSection = ({
  avFeatureTiles: { ftHeading, ftMessage, ftTiles, ftVideo, ftShowVideo },
}) => {
  return (
    <Grid mainColumn>
      <Spacer height={[28, 56, 90, 100]} />
      {ftShowVideo ? (
        <Box mb={[32, null, 50, 70]}>
          <Row xsGap={24} mdGap={35} mdRowGap={20} lgRowGap={40} lgGap={55}>
            <Col xs={12}>
              <Heading>{ftHeading}</Heading>
              <Spacer height={[0, 0, 20]} />
            </Col>
            <Col xs={12} md={5.5} lg={4.9}>
              <Paragraph dangerouslySetInnerHTML={{ __html: ftMessage }} />
            </Col>

            <Col xs={12} md={6.5} lg={7.1}>
              <Video video={ftVideo} />
            </Col>
          </Row>
        </Box>
      ) : (
        <Row smGap={21} mdGap={72}>
          <Col xs={12} sm={5} md={5} xsAlignX="center" mdAlignX="start">
            <Heading>{ftHeading}</Heading>
          </Col>
          <Col xs={12} sm={7} md={7} xsAlignX="center" mdAlignX="start">
            <Paragraph dangerouslySetInnerHTML={{ __html: ftMessage }} />
          </Col>
        </Row>
      )}
      <Row>
        {ftTiles.map((tile, index) => {
          return (
            <Col
              xs={12}
              sm={4}
              md={4}
              xsAlignX="center"
              mdAlignX="start"
              key={Math.random() + index}
            >
              <Highlight tile={tile}></Highlight>
            </Col>
          )
        })}
      </Row>
      <Spacer height={[20, 60]} />
    </Grid>
  )
}

export default HighlightsSection
