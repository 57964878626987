import React, { Component } from "react"
import AvenueImg from "UI/AvenueImg"
import Slider from "react-slick"
import "./transitions.css"
import "./slick.css"
import "./slick-theme.css"
import "./animate.css"
import { Icon, Box } from "UI"
import {
  Container,
  Featured,
  ButtonContainer,
  Button,
  FeaturedPhoto,
  TextBlock,
  Dot,
} from "./styled"
import { CSSTransition, TransitionGroup } from "react-transition-group"

const TextGroup = ({ testimonial, author }) => {
  return (
    <div className="info-slide faster5">
      <h5 dangerouslySetInnerHTML={{ __html: testimonial }} />

      <span dangerouslySetInnerHTML={{ __html: author }} />
    </div>
  )
}
export default class TestimonialCarousel extends Component {
  constructor(props) {
    super(props)
    this.navRef = React.createRef()
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goTo = this.goTo.bind(this)
    this.slideChanged = this.slideChanged.bind(this)

    this.testimonialsData = this.props.data.tsItems
    this.state = {
      current: this.testimonialsData[0],
      author: this.testimonialsData[0].author,
      testimonial: this.testimonialsData[0].testimonial,
      disabled: false,
      previousIndex: 0,
      currentIndex: 0,
    }
  }

  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  slideChanged(oldIndex, newIndex) {
    this.setState(
      {
        current: this.testimonialsData[newIndex],
        currentIndex: newIndex,
        previousIndex: oldIndex,
      },
      () => this.updateInfo(oldIndex, newIndex)
    )
  }

  goTo(element) {
    this.slider.slickGoTo(element)
  }

  animateCSS(element, animationName, callback) {
    const node = document.querySelector(element)
    node.classList.add("animated", animationName)

    function handleAnimationEnd() {
      node.classList.remove("animated", animationName)
      node.removeEventListener("animationend", handleAnimationEnd)

      if (typeof callback === "function") {
        callback()
      }
    }

    node.addEventListener("animationend", handleAnimationEnd)
  }

  updateInfo(oldIndex, newIndex) {
    this.setState({
      author: this.state.current.author,
      testimonial: this.state.current.testimonial,
    })
  }

  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 1100,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.slideChanged,
    }

    return (
      <Container>
        <Featured>
          <Icon name="ll-arrow" color="#47C5F3" width={[66, null, 90, 115]} />
          <Icon name="ll-arrow" color="#1870B0" width={[22, null, 30, 39]} />
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.testimonialsData.map((item, index) => {
              return (
                <FeaturedPhoto key={index}>
                  <AvenueImg
                    style={{ position: "absolute", width: "100%" }}
                    fluid={item.image}
                    alt=""
                  />
                </FeaturedPhoto>
              )
            })}
          </Slider>
        </Featured>
        <TextBlock>
          <Icon
            name="quotation-marks"
            width={[45, 40, 50, 68]}
            color="#47C5F3"
          />
          <TransitionGroup className="transitionGroup">
            <CSSTransition
              key={this.state.currentIndex}
              in={true}
              appear={true}
              timeout={1000}
              mountOnEnter={true}
              unmountOnExit={true}
              enter={true}
              exit={true}
              classNames="fade"
            >
              <TextGroup
                testimonial={this.state.testimonial}
                author={this.state.author}
              />
            </CSSTransition>
          </TransitionGroup>

          <Box
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            mt="auto"
          >
            <Box direction="row">
              {this.testimonialsData.map((item, index) => {
                return (
                  <Dot
                    active={index === this.state.currentIndex}
                    key={index}
                    onClick={() => this.goTo(index)}
                  />
                )
              })}
            </Box>
            <ButtonContainer>
              <Button previous onClick={this.previous}>
                <Icon
                  name="ll-arrow"
                  color="white"
                  transform="scale(-1)"
                  width="16px"
                  height="24px"
                />
              </Button>
              <Button next onClick={this.next}>
                <Icon
                  name="ll-arrow"
                  color="white"
                  width="16px"
                  height="24px"
                />
              </Button>
            </ButtonContainer>
          </Box>
        </TextBlock>
      </Container>
    )
  }
}
