import styled from "styled-components"
import { device } from "breakpoints"

export const Paragraph = styled.div`
  max-width: 746px;
  margin: 0 auto;
  width: 100%;
  p {
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    padding-bottom: 10px;
    @media ${device.tablet} {
      font-size: 17px;
    }

    @media ${device.desktop} {
      font-size: 18px;
    }
  }
`
