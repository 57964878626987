import styled from "styled-components"
import { device } from "breakpoints"
import { Text, Icon } from "UI"
import { Link } from "gatsby"

export const Container = styled.div`
  background: #1870b0;
  padding: 58px 10px 40px 10px;
  overflow: hidden;
  position: relative;

  @media ${device.desktop} {
    padding: 105px 10px 108px 10px;
  }
`

export const Heading = styled(Text)`
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 23px;
  max-width: 750px;

  @media ${device.desktop} {
    font-size: 36px;
    margin-bottom: 22px;
    max-width: 950px;
  }
`

export const Description = styled(Text)`
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #9fe5ff;
  max-width: 500px;
  margin: 0 auto;

  @media ${device.desktop} {
    font-size: 18px;
    margin-bottom: 47px;
    max-width: 750px;
  }
`

export const TeasersWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: center;
  }
`

export const TeaserTitle = styled(Text)`
  font-size: 18px;
  text-align: center;
  color: #ffffff;
`

export const TeaserImg = styled.img`
  width: 200px;
  margin-bottom: 16px;

  @media ${device.desktop} {
    margin: 0 68px 40px 68px;
    width: 262px;
  }
`

export const Teaser = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 29px 0 26px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #008cd1;
  }

  @media ${device.desktop} {
    &:not(:last-of-type) {
      border-right: 1px solid #008cd1;
      border-bottom: unset;
    }

    ${TeaserImg} {
      transform: scale(1);
      transition: ease-in-out 0.25s transform;
    }

    :hover {
      ${TeaserImg} {
        transform: scale(1.1);
      }
    }
  }
`

export const Arrow = styled(Icon)`
  position: absolute;
  z-index: 10;
`
