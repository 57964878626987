import React from "react"
import { Box } from "UI"
import { MainColumn, Spacer } from "../UI"
import ServicesBlock from "./ServicesBlock"
import { Title, Subtitle } from "./style"

const Services = ({ data }) => {
  return (
    <Box backgroundColor="#303030">
      <MainColumn>
        <Box p={["44px 0 22px", "55px 0 ", "105px 0"]}>
          <Title p={["0 11px"]}>{data?.avSolutionTiles?.stHeading}</Title>
          <Spacer height={[22]} />
          <Box
            maxWidth={[null, null, "697px"]}
            m={[null, null, "0 auto"]}
            width="100%"
          >
            <Subtitle
              dangerouslySetInnerHTML={{
                __html: data?.avSolutionTiles?.stExcerpt,
              }}
            />
          </Box>
          <Spacer height={[32, null, 55]} />
          <ServicesBlock
            services={data?.avSolutionTiles?.stItems}
            all={data?.avSolutionTiles?.stViewAll}
          />
        </Box>
      </MainColumn>
    </Box>
  )
}

export default Services
