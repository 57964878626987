import React from "react"
import { Link } from "gatsby"
import { Box, Spacer, Icon } from "UI"
import {
  Title,
  ServiceContent,
  ServiceBlockContainer,
  BlockSubtitle,
} from "./style"

const colors = ["#32BCEF", "#FD8211", "#008CD1", "#EC5500", "#1870B0"]

const ServicesBlock = ({ services, all }) => {
  const [firstItem] = services
  const otherServices = services.slice(1)

  return (
    <Box>
      <Link to={firstItem.page.uri}>
        <Box
          backgroundColor="#1870B0"
          p={["38px 39px", null, null, "40px 57px"]}
          borderRadius="10px"
          flexDirection={["column !important", "row !important"]}
          justifyContent={[null, "space-between"]}
          alignItems={[null, "center"]}
        >
          <Box width={[null, "60%"]}>
            <Title
              fontSize={["24px !important", null, "32px !important"]}
              textAlign="left !important"
            >
              {firstItem?.page.title}
            </Title>
            <Spacer height={[18]} />
            <BlockSubtitle
              dangerouslySetInnerHTML={{ __html: firstItem?.content }}
            />
          </Box>
          <Spacer width={["100%", 0]} height={[28, 0]} />
          <Box width={["100%", "27%"]} maxWidth={["400px"]}>
            <Icon name={firstItem?.icon} />
          </Box>
        </Box>
      </Link>
      <Box
        flexDirection={[null, null, "row"]}
        flexWrap={[null, null, "wrap"]}
        justifyContent={[null, null, "space-between"]}
      >
        {otherServices.map((service, index) => {
          if (!service.page) return false
          return (
            <ServiceBlockContainer key={Math.random() + index}>
              <Link to={service.page.uri}>
                <Box
                  backgroundColor={colors[index]}
                  borderRadius={["10px", null, "5px"]}
                  flexDirection={["row", null, "column"]}
                  alignItems="center"
                  p={["27px", null, null, "35px 45px"]}
                  position="relative"
                  height={["95px", null, "240px"]}
                  justifyContent={[null, null, "space-between"]}
                >
                  <Box
                    width={["18%", "36px", "64px"]}
                    maxWidth={["36px", null, "64px"]}
                    mb={[null, null, "20px"]}
                  >
                    <Icon name={service.icon} size={[36, 36, 64]} />
                  </Box>
                  <Title
                    width={["70%", "100%"]}
                    fontSize={[
                      "18px !important",
                      "20px !important",
                      null,
                      "24px !important",
                    ]}
                    ml={["24px !important", null, "0 !important"]}
                    textAlign={["left !important", null, "center !important"]}
                    lineHeight={[
                      "22px !important",
                      "26px !important",
                      null,
                      "125% !important",
                    ]}
                  >
                    {service.page.title}
                  </Title>
                  <ServiceContent backgroundColor={colors[index]}>
                    <div
                      dangerouslySetInnerHTML={{ __html: service.content }}
                    />
                  </ServiceContent>
                </Box>
              </Link>
            </ServiceBlockContainer>
          )
        })}
        <ServiceBlockContainer>
          <Link to={all?.uri || "/"}>
            <Box
              backgroundColor="#D94E00"
              borderRadius={["10px", null, "5px"]}
              flexDirection={["row", null, "column"]}
              alignItems="center"
              p={["27px", null, null, "35px 45px"]}
              height={["95px", null, "240px"]}
              justifyContent={[null, null, "space-between"]}
            >
              <Box
                width={["18%", "36px", "64px"]}
                maxWidth={["36px", null, "64px"]}
                mb={[null, null, "20px"]}
              >
                <Icon name="file" size={[36, 36, 64]} />
              </Box>
              <Title
                width={["70%", "100%"]}
                fontSize={[
                  "18px !important",
                  "20px !important",
                  null,
                  "24px !important",
                ]}
                ml={["24px !important", null, "0 !important"]}
                textAlign={["left !important", null, "center !important"]}
                lineHeight={["22px !important", null, "125% !important"]}
              >
                View all Solutions
              </Title>
            </Box>
          </Link>
        </ServiceBlockContainer>
      </Box>
    </Box>
  )
}

export default ServicesBlock
