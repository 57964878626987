import React from "react"
import { Grid, Row, Col } from "UI/Grid"
import { Box, Text, MainColumn, Spacer } from "UI"
import Arrows from "./Arrows"
import { Paragraph } from "./style"

const Item = ({ primaryText, secondaryText, last }) => {
  return (
    <Box
      borderBottom={[!last && "1px solid #CED0D2", "none"]}
      borderRight={[null, !last && "1px solid #CED0D2"]}
      p={[null, "0 20px"]}
      pt={[null, 10, 16]}
      height={[null, "100%"]}
    >
      <Text as="h3" color="#008CD1" mb={["4px", "6px"]} fontSize={[32, 36, 42]}>
        {primaryText}
      </Text>
      <Text mb={[23, 15]}>{secondaryText}</Text>
    </Box>
  )
}

const StatsSection = ({ data }) => {
  return (
    <Box background="#f5f5f5" overflow="hidden">
      <Box
        background="#f5f5f5"
        maxWidth={1430}
        m="0 auto"
        pb={[30, 50, 90]}
        position="relative"
        width="100%"
      >
        <Arrows />
        <MainColumn>
          <Spacer height={[50, 60, 100]} />
          <Box textAlign="center">
            <Text
              as="h3"
              fontSize={[28, 32, 36]}
              p={["0px 10px"]}
              maxWidth={949}
              m="0 auto"
              width="100%"
            >
              {data.statHeading}
            </Text>
            <Spacer height={[25]} />

            <Paragraph
              dangerouslySetInnerHTML={{
                __html: data.statExcerpt,
              }}
            />

            <Spacer height={[25, 30, 49]} />

            <Box maxWidth={[480, "100%"]} m="0 auto" width="100%">
              <Grid>
                <Row xsGap={28} smGap={0}>
                  {data.statItems.map((item, index) => {
                    return (
                      <Col xs={12} sm={3} md={3}>
                        <Item
                          primaryText={item.statistic}
                          secondaryText={item.description}
                          last={index === data.statItems.length - 1}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Grid>
            </Box>
          </Box>
        </MainColumn>
      </Box>
    </Box>
  )
}

export default StatsSection
