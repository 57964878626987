import React from "react"
import { HomeTemplateContainer } from "./style"
import { HighlightsSection } from "./HighlightsSection"
import { FeaturesSection } from "./FeaturesSection"
import { Services } from "components/Services"
import { StatsSection } from "./StatsSection"
import { TestimonialCarousel } from "./TestimonialCarousel"
import { LatestResources } from "../../Project/LatestResources"
import { GeoTeasers } from "./GeoTeasers"

const HomeTemplate = ({ data }) => {
  // const { previewData } = useContext(PreviewContext)

  const { avCarousel, avStatistics, avTestimonials, avLocations } = data

  return (
    <HomeTemplateContainer>
      <HighlightsSection avFeatureTiles={data.avFeatureTiles} />
      {/* add sections here */}
      {avCarousel && <FeaturesSection data={avCarousel} />}
      <Services data={data} />
      {avLocations && <GeoTeasers data={avLocations} />}
      {avStatistics && <StatsSection data={avStatistics} />}
      {avTestimonials && <TestimonialCarousel data={avTestimonials} />}
      <LatestResources />
    </HomeTemplateContainer>
  )
}

export default HomeTemplate
