import styled from "styled-components"
import { device } from "breakpoints"
import { Text, Box } from "UI"

export const Title = styled(Text)`
  font-family: ${(props) => props.theme.headerFont};
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #fff;

  @media ${device.desktop} {
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.01em;
  }
`

export const Subtitle = styled.div`
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: ${(props) => props.theme.colors.softGrey};
    margin-bottom: 10px;

    @media ${device.desktop} {
      font-size: 18px;
      line-height: 150%;
      text-align: center;
    }
  }
`
export const BlockSubtitle = styled(Subtitle)`
  color: #fff;
  text-align: left !important;
`

export const ServiceContent = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  background: ${(props) => props.backgroundColor};
  padding: 35px 33px 28px;
  border-radius: 5px;
  height: 100%;
  transition: 500ms;

  ul {
    list-style: none;
  }
  li {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 8px;
    padding-top: 8px;

    :first-child {
      padding-top: 0;
    }
    :last-child {
      border-bottom: 0;
    }
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: #fff;
  }
`
export const ServiceBlockContainer = styled(Box)`
  margin-top: 20px;
  overflow: hidden;

  @media ${device.desktop} {
    width: 31%;
    margin-top: 40px;

    :hover {
      ${ServiceContent} {
        transform: translateY(-100%);
      }
    }
  }
`

export const CustomServicesBlockContent = styled.div`
  background: ${(props) => props.backgroundColor};
  border-radius: 5px;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #fff;
    text-align: center;
  }
`
export const CustomServicesBlockContainer = styled(Box)`
  width: 100%;
  svg {
    width: 40px;
    height: 40px;
  }

  @media ${device.tablet} {
    max-width: 278px;
  }
  @media ${device.desktop} {
    p {
      font-size: 18px;
    }
    svg {
      width: 64px;
      height: 64px;
    }
  }
`

export const CustomServicesBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  margin-top: 24px;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 16px;
    align-items: unset;
  }
  @media ${device.desktop} {
    margin-top: 48px;
  }
`

export const IconServiceBlock = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  position: relative;
  background: ${(props) => props.backgroundColor};
  gap: 16px;
  height: 100%;
  @media ${device.tablet} {
    padding: 32px 24px;
  }
`
