import styled from "styled-components"
import { device } from "breakpoints"

export const Tile = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 29px 38px;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    @media ${device.tablet} {
      padding: 36px 35px;

      .icon {
        transition: transform 250ms ease-out;
      }

      :hover {
        .icon {
          transform: scale(0.7);
        }
      }
    }

    @media ${device.desktop} {
      padding: 50px 35px;
    }
`

export const IconHeading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 250ms transform ease-out;
    width: 100%;
    ${Tile}:hover & {
      @media ${device.tablet} {
        transform: translateY(-30px);
      }
      
      @media ${device.desktop} {
        transform: translateY(-35px);
      }

      @media ${device.large} {
        transform: translateY(-30px);
      }
    }
`

export const Heading = styled.h3`
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.01em;
    line-height: 125%;
    text-align: center;
    margin-top: 16px;
    transition: all 250ms ease-out;
    width: 100%;
    @media ${device.tablet} {
      font-size: 16px;
      margin-top: 24px;
    }

    @media ${device.desktop} {
      font-size: 24px;
      margin-top: 32px;
    }

    ${Tile}:hover & {
      @media ${device.tablet} {
        margin-top: 0px;
      }
    }
`

export const SlideUpText = styled.div`
    display: block;
    position: absolute;
    left: 0;
    bottom: -90px;
    transition: bottom 250ms ease-out;
    height: 100px;
    width: 100%;
    padding: 26px 32px;
    background: ${(props) => props.theme.colors.indigo};
    color: #fff;

    @media ${device.tablet} {
      font-size: 14px;
      padding: 12px 15px;
      height: 80px;

      ${Tile}:hover & {
        bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    @media ${device.desktop} {
      font-size: 16px;
      padding: 25px 32px;
      height: 100px;
    }
`

export const SlideUpTextContent = styled.div`
  text-align: center;
`
