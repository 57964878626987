import React, { useState } from "react"

import styled from "styled-components"
import { device } from "breakpoints"
import ReactPlayer from "react-player"
import AvenueImg from "UI/AvenueImg"
import { Icon } from "UI/Icon"

export const VideoWrapper = styled.div`
  background: black;
  padding-top: 56.3%;
  position: relative;

  > div,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  svg {
    opacity: 1;
    transition: 200ms;
  }
  :hover {
    svg {
      opacity: 0.6;
    }
  }
`
export const Thumbnail = styled(AvenueImg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`
export const PlayIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10;
  width: 130px;
  height: 130px;

  @media ${device.tablet} {
    width: 166px;
    height: 166px;
  }
`

function Video({ video }) {
  const { thumbnailImage: thumbnail, url } = video || {}

  const [loaded, setLoaded] = useState(!thumbnail)

  const [playing, setPlaying] = useState(false)

  if (!url) return null
  return (
    <button
      aria-label="Play Video"
      style={{
        display: "block",
        width: "100%",
        cursor: "pointer",
      }}
      type="button"
      onClick={() => {
        if (thumbnail) {
          setLoaded(true)
          setPlaying(true)
        }
      }}
    >
      <VideoWrapper>
        {thumbnail && !loaded && (
          <div>
            <Thumbnail fluid={{ sourceUrl: thumbnail?.sourceUrl }} alt="" />

            <PlayIcon name="play-alt" />
          </div>
        )}

        {loaded && (
          <ReactPlayer controls url={url} playing={playing} volume={1} />
        )}
      </VideoWrapper>
    </button>
  )
}

export default Video
