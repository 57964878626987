import styled from "styled-components"
import { device } from "breakpoints"

export const Heading = styled.h2`
  font-weight: bold;
  font-size: 28px;
  line-height: 110%;
  color: #000000;
  width: 100%;

  @media ${device.tablet} {
    font-size: 32px;
  }

  @media ${device.desktop} {
    font-size: 36px;
  }
`

export const Paragraph = styled.div`
  p {
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    padding-bottom: 10px;
    @media ${device.tablet} {
      font-size: 17px;
    }

    @media ${device.desktop} {
      font-size: 18px;
    }
  }
`
