import React, { useState } from "react"
import { Container, ListWrapper, Trigger, Item } from "./style"
import { useMedia } from "helpers/useMedia.js"
import { Text, Icon } from "UI"
const FeaturesList = ({ items, setSelectedIndex, selectedIndex }) => {
  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  const [showList, setShowList] = useState(isDesktop)
  const [currentHeader, setCurrentHeader] = useState(items[0].heading)

  return (
    <Container>
      <Trigger onClick={() => setShowList(!showList)} showList={showList}>
        <Text>{currentHeader}</Text>
        <Icon
          name="ll-arrow"
          transform={showList ? "rotate(-90deg)" : "rotate(-90deg) scale(-1)"}
          width={11}
        />
      </Trigger>

      <ListWrapper showList={showList}>
        {items?.map((item, index) => {
          const active = index === selectedIndex

          return (
            <Item
              active={active}
              onClick={() => {
                setSelectedIndex(index)
                setCurrentHeader(item.heading)
                !isDesktop && setShowList(false)
              }}
            >
              <Text>{item.heading}</Text>
            </Item>
          )
        })}
      </ListWrapper>
    </Container>
  )
}

export default FeaturesList
