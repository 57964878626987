import React, { useState } from "react"
import { Grid, Row, Col } from "UI/Grid"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Box, Text, MainColumn, Spacer } from "UI"
import { FeaturesList } from "./FeaturesList"
import SelectedContent from "./SelectedContent"
import "./transitions.css"
const FeaturesSection = ({ data }) => {
  const items = data.fcItems
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Box background="#f5f5f5">
      <MainColumn>
        <Spacer height={[40, 45, 100]} />
        <Box maxWidth={[760, null, "100%"]} m="0 auto">
          <Text
            as="h3"
            fontSize={[28, 32, 36]}
            textAlign="center"
            p={["0px 10px"]}
          >
            {data?.fcHeading}
          </Text>
          <Spacer height={[25, 30, 45]} />
          <Grid>
            <Row>
              <Col xs={12} md={4}>
                <FeaturesList
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  items={items}
                />
              </Col>

              <Col xs={12} md={8}>
                <TransitionGroup className="transitionGroup">
                  <CSSTransition
                    key={selectedIndex}
                    in={true}
                    appear={true}
                    timeout={1000}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    enter={true}
                    exit={true}
                    classNames="fade"
                  >
                    <SelectedContent item={items[selectedIndex]} />
                  </CSSTransition>
                </TransitionGroup>
              </Col>
            </Row>
          </Grid>
        </Box>
      </MainColumn>
    </Box>
  )
}

export default FeaturesSection
