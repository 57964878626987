import styled from "styled-components"
import { device } from "breakpoints"
export const Container = styled.div`
  margin-bottom: 10px;
  position: relative;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`
export const Trigger = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 60px;
  border-radius: ${(props) => props.showList && "25px 25px 0px 0px "};
  align-items: center;
  padding: 13px 25px;
  box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  @media ${device.desktop} {
    display: none;
  }
  .icon {
    transition: 100ms;
  }
`

export const ListWrapper = styled.div`
  display: ${(props) => (props.showList ? "flex" : "none")};
  flex-direction: column;
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.15);
  width: 100%;
  border-radius: 0 0 25px 25px;
  z-index: 3;

  @media ${device.desktop} {
    display: flex;
    box-shadow: none;
    transform: none;
    position: relative;
    border-radius: 0;
    background: none;
  }
`

export const Item = styled.button`
  cursor: pointer;
  padding: 13px 25px;
  color: #575a5e;
  text-align: left;
  border-top: 1px solid ${(props) => props.theme.colors.softGrey};
  color: ${(props) => props.active && "white"};
  background: ${(props) => props.active && props.theme.primaryColor};

  :last-of-type {
    border-radius: 0 0 25px 25px;
  }

  @media ${device.desktop} {
    background: none;
    font-weight: bold;
    color: ${(props) => props.active && "#008CD1"};
    border: none;
    padding: 18px 0;
    border-radius: 0 !important;
    transition: ${(props) => props.active && "200ms"};

    p {
      display: inline;
      padding-bottom: 3px;
      position: relative;
      background-image: linear-gradient(to right, #008cd1, #008cd1);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 0;
      background-size: ${(props) => props.active && "100% 2px"};
    }
  }
`
