import React from "react"
import { Icon } from "UI"
import {
  CustomServicesBlockContent,
  CustomServicesBlockContainer,
  IconServiceBlock,
  CustomServicesBlockWrapper,
} from "./style"

const colors = [
  "#32BCEF",
  "#FD8211",
  "#008CD1",
  "#EC5500",
  "#1870B0",
  "#32BCEF",
  "#FD8211",
  "#008CD1",
  "#EC5500",
  "#1870B0",
]

const CustomServicesBlock = ({ items }) => {
  return (
    <CustomServicesBlockWrapper>
      {items.map((item, index) => {
        const { content, icon } = item || {}

        return (
          <CustomServicesBlockContainer key={Math.random() + index}>
            <IconServiceBlock backgroundColor={colors[index]}>
              <Icon name={icon} />
              <CustomServicesBlockContent backgroundColor={colors[index]}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </CustomServicesBlockContent>
            </IconServiceBlock>
          </CustomServicesBlockContainer>
        )
      })}
    </CustomServicesBlockWrapper>
  )
}

export default CustomServicesBlock
