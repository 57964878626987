import React from "react"
import {
  Container,
  Heading,
  Description,
  Teaser,
  TeaserTitle,
  TeaserImg,
  TeasersWrapper,
  Arrow,
} from "./style"
import { MainColumn } from "UI"

const Arrows = () => {
  return (
    <>
      {/* 1 */}
      <Arrow
        name="ll-arrow"
        color="#32BCEF"
        width={[72, null, 186]}
        top={[113, null, 154]}
        left={[-23, null, 10, 61]}
        opacity={0.3}
      />

      {/* 2 */}
      <Arrow
        display={["none", "none", "block"]}
        name="ll-arrow"
        color="#156096"
        width={[null, null, 49]}
        top={[null, null, 329]}
        left={[null, null, -20, -11]}
        opacity={0.8}
      />

      {/* 3 */}
      <Arrow
        display={["none", "none", "block"]}
        name="ll-arrow"
        color="#156096"
        width={[null, null, 181]}
        top={[null, null, -48]}
        right={[null, null, -20, 93]}
        opacity={0.8}
      />

      {/* 4 */}
      <Arrow
        display={["none", "none", "block"]}
        name="ll-arrow"
        color="#47C5F3"
        width={[null, null, 53]}
        top={[null, null, 141, 121]}
        right={[null, null, -40, 40]}
        opacity={0.9}
      />
    </>
  )
}

const GeoTeaser = ({ data }) => {
  const { label, image, linkPage } = data
  const { sourceUrl, srcSet } = image || {}
  const { uri } = linkPage || {}

  return (
    <Teaser href={uri}>
      <TeaserImg src={sourceUrl} srcSet={srcSet} />
      <TeaserTitle as="h3">{label}</TeaserTitle>
    </Teaser>
  )
}

const GeoTeasers = ({ data }) => {
  const { locDescription, locHeading, locItems } = data

  return (
    <Container>
      <Arrows />
      <MainColumn>
        <Heading as="h2">{locHeading}</Heading>
        <Description as="p">{locDescription}</Description>
        <TeasersWrapper>
          {locItems.map((item, index) => (
            <GeoTeaser data={item} key={index} />
          ))}
        </TeasersWrapper>
      </MainColumn>
    </Container>
  )
}

export default GeoTeasers
