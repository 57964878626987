import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "UI/MainColumn"

export const Container = styled(MainColumn)`
  margin: 20px auto;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  @media (min-width: 768px) {
    margin: 50px auto;
    flex-direction: row;
  }

  @media ${device.desktop} {
    margin: 80px auto;
  }
`

export const Featured = styled.div`
  position: relative;
  overflow: hidden;
  background: white;

  @media ${device.tablet} {
    width: 50%;
  }
  .icon {
    position: absolute;
    z-index: 3;

    :nth-of-type(1) {
      top: 20px;
      left: -20px;

      @media ${device.desktop} {
        top: 30px;
        left: -30px;
      }

      @media ${device.large} {
        top: 30px;
        left: -40px;
      }
    }

    :nth-of-type(2) {
      top: 104px;
      left: 30px;

      @media ${device.desktop} {
        top: 150px;
        left: 40px;
      }

      @media ${device.large} {
        top: 180px;
        left: 50px;
      }
    }
  }
`

export const FeaturedPhoto = styled.div`
  display: block !important;
  position: relative;
  padding-top: 70%;

  @media ${device.tablet} {
    padding-top: 155%;
  }

  @media ${device.desktop} {
    padding-top: 135%;
  }

  @media ${device.large} {
    padding-top: 107%;
  }

  .avenueImg {
    border-radius: 10px 10px 0 0;
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media ${device.tablet} {
      border-radius: 5px 0 0 5px;
    }
  }
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 10px 10px;
  background: ${(props) => props.theme.primaryColor};
  padding: 45px 30px 25px 30px;
  position: relative;
  color: white;
  overflow: hidden;
  @media ${device.tablet} {
    padding: 45px 45px 25px 45px;

    border-radius: 0 5px 5px 0;
    width: 50%;
  }

  @media ${device.desktop} {
    padding: 55px 60px 30px 60px;
  }
  @media ${device.large} {
    padding: 64px 70px 40px 70px;
  }

  .icon.quotation-marks {
    position: absolute;
    top: -4px;
    left: 30px;
    z-index: 3;

    @media ${device.tablet} {
      left: -10px;
      top: 20px;
    }

    @media ${device.desktop} {
      left: -12px;
      top: 16px;
    }

    @media ${device.large} {
      left: -17px;
      top: 37px;
    }
  }

  div.info-slide {
    h5 {
      color: inherit;
      font-size: 16px;
      line-height: 150%;
      font-weight: 400;
      @media ${device.tablet} {
        font-size: 18px;
      }

      @media ${device.desktop} {
        font-size: 22px;
      }
      @media ${device.large} {
        font-size: 24px;
      }
    }

    span {
      font-weight: bold;
      font-size: 12px;
      margin-top: 15px;
      display: block;
      @media ${device.tablet} {
        font-size: 14px;
        margin-top: 18px;
      }
    }
    margin-bottom: 32px;

    @media ${device.tablet} {
      margin-bottom: 20px;
    }

    @media ${device.desktop} {
      margin-bottom: 30px;
    }

    /* set height */
    @media ${device.tablet} {
      height: 300px;
    }

    @media ${device.desktop} {
      height: 370px;
    }

    @media ${device.large} {
      height: 330px;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  z-index: 3;

  @media ${device.desktop} {
    margin-right: -20px;
  }

  @media ${device.large} {
    margin-right: -30px;
  }
`

export const Button = styled.div`
  opacity: 1;
  transition: 130ms;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  opacity: ${(props) => props.disabled && 0.5};
  pointer-events: ${(props) => props.disabled && "none"};

  svg {
    height: 24px;
    width: 16px;
  }

  :nth-child(1) {
    padding-right: 15px;

    @media ${device.desktop} {
      padding-right: 18px;
    }
  }

  :nth-child(2) {
    padding-left: 15px;

    @media ${device.desktop} {
      padding-left: 18px;
    }
  }
`

export const Dot = styled.button`
  height: 8px;
  width: 8px;
  margin-right: 12px;
  border-radius: 50%;
  transition: 170ms;
  background: ${(props) => (props.active ? "white" : "#47c5f3")};
  cursor: pointer;
`
