import React from "react"
import { Box, Icon, Text, Spacer, StyledLink } from "UI"
import { CircleImageWrapper, Excerpt } from "./style"

import AvenueImg from "UI/AvenueImg"

const SelectedContent = ({ item }) => {
  return (
    <Box
      direction={["column", "row"]}
      alignItems={["center", "flex-start"]}
      width="100%"
    >
      <CircleImageWrapper>
        <AvenueImg fluid={item.image} />
        <Icon name="arrows-carousel" />
      </CircleImageWrapper>

      <Box
        width="100%"
        alignItems={["center", "flex-start"]}
        pt={[null, 40, 20]}
        pl={[null, "5px", "7px"]}
      >
        <Text
          as="h3"
          fontSize={[20, 24]}
          width="100%"
          textAlign={["center", "left"]}
        >
          {item?.heading}
        </Text>
        <Spacer height={[16, 24]} />
        <Excerpt dangerouslySetInnerHTML={{ __html: item?.excerpt }} />
        <Spacer height={[10, 17]} />

        <Box borderTop="1px solid #CED0D2" width="100%">
          <StyledLink
            to={item?.page.uri}
            m={["0 auto", 0]}
            mt={[30, 35]}
            mb={[50, 0]}
          >
            Learn more
          </StyledLink>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectedContent
