import React from "react"
import styled from "styled-components"
import { Icon } from "UI"
export const Arrow = styled(Icon)`
  position: absolute;
  z-index: 10;
`
const Arrows = () => {
  return (
    <>
      {/* 1 */}
      <Arrow
        name="ll-arrow"
        color="#CED0D2"
        width={[93, null, 130, 181]}
        bottom={[-50, -70, -80]}
        right={[-20, -10, -30, -50]}
        opacity={0.3}
      />

      {/* 2 */}
      <Arrow
        display={["none", "block"]}
        name="ll-arrow"
        color="#AEB2B6"
        width={[null, 60, 90, 118]}
        top={[null, -30, -40, -40]}
        z
        left={[null, 0, 20, 60]}
        opacity={0.3}
      />

      {/* 3 */}
      <Arrow
        display={["none", "none", "block"]}
        name="ll-arrow"
        color="#C4C4C4"
        width={[null, null, 20, 34]}
        top={[null, null, 70, 110]}
        left={[null, null, 80, 150]}
        opacity={0.3}
      />
    </>
  )
}

export default Arrows
